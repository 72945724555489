import {createTheme} from "@mui/material/styles";
import {frFR,enUS} from '@mui/material/locale';

let theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            dark: '#6fbb60',
            light: '#F1592A'
        },
        secondary: {
            main: '#2f2f2f',
            light: '#eaeaea',
            dark: '#051f32'
        },
    },
    frFR,
    enUS
})

theme = createTheme(theme, {
    typography:{
        h1:{
            fontSize: '2.5rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.6rem'
            },
        },
        h2:{
            fontSize: '2.3rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.5rem'
            },
        },
        h3:{
            fontSize: '2.1rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.4rem'
            },
        },
        h4:{
            fontSize: '1.8rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.3rem'
            },
        },
        h5:{
            fontSize: '1.5rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.2rem'
            },
        },
        h6:{
            fontSize: '1.3rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.15rem'
            },
        },
        body1:{
            fontSize: '1.15rem'
        },
        body2:{
            fontSize: '1.15rem'
        }
    },
    components: {
        Mui:{
            styleOverrides:{
                focuses: {
                    borderColor:'Black',
                }
            }
        },
        MuiButton: {
            styleOverrides:{
                root: {
                    backgroundColor: theme.palette.primary.light,
                    color:'black',
                    fontSize: '1.5rem',
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                    }
                },
                containedSecondary: {
                    backgroundColor: theme.palette.primary.light,
                    "&:hover": {
                        backgroundColor: theme.palette.secondary.dark,
                    }
                },
            },
        },
        MuiLink:{
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    color: '#000000',
                    "&:hover": {
                        color: theme.palette.primary.dark
                    }
                },
                underlineHover: {
                    "&:hover": {
                        color: '#000000'
                    }
                }
            }
        },
        MuiTextField:{
            styleOverrides:{
                root:{
                    margin: theme.spacing(2),
                    marginLeft: 0,
                },
            }
        },
        MuiCardActionArea:{
            styleOverrides:{
                root:{
                    "&:hover":{
                        backgroundColor: theme.palette.primary.light,
                        opacity: 0.9,
                        color:'white'
                    }
                }
            }
        }
    },
})

export default theme