import React from 'react';
import {Card, CardActionArea} from "@mui/material";
import {myStyles} from "components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function CardSelectAction({title, navigateTo}) {
	const {t} = useTranslation();
	const navigate = useNavigate();

	return (
		<Card onClick={() => navigate(navigateTo)} sx={myStyles.cardSelectionRequired}>
			<CardActionArea sx={myStyles.cardSelectionRequiredAction} >
				<h3>{t(title)}</h3>
			</CardActionArea>
		</Card>
	);
}

export default CardSelectAction;