import React from 'react';
import {useTranslation} from 'react-i18next'
import {BackToTop} from "components";
import {Container} from "@mui/material";

function PublicRoute({element}) {
    const {t} = useTranslation();

    return (
        <>
            {/*<Header/>*/}
            <BackToTop />
            <Container maxWidth="true" disableGutters className="application-container" sx={{height:'100%', background:'white'}}>
                {element}
            </Container>
            {/*<Footer />*/}
        </>
    );
}

export default PublicRoute;