import React, {useState} from 'react';
import {CardSendNotification, NotificationAlert, Title, TitleWithBack} from "components";
import {Backdrop, CircularProgress, Container, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import apiClient from "../../../services/apiClient";
import {useLogContext} from "../../../contexts/logContext";
import Swal from "sweetalert2";
import theme from "../../../theme";

function SelectAvisRoadPage() {
  const {t} = useTranslation()
  const { handleAction } = useLogContext()
  const {enterprise, idEmailMessage} = useParams()
  const [roads, setRoads] = useState([]);
  const [errors, setErrors] = useState({});

  const { isLoading } = useQuery(
    ['fetchAllRoadsByEnterpriseId'],
    async () => {
      return await apiClient.fetchAllRoadsByEnterpriseId(enterprise);
    },
    {
      enabled: true,
      onSuccess: ({data}) => {
        setRoads(data)
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );
  const { mutate: sendNotification } = useMutation(
    ['sendNotification'],
    async (roadId) => {
      return await apiClient.sendAvisTempete(roadId, idEmailMessage, enterprise);
    },
    {
      onSuccess: ({data}) => {
        handleAction(`${data.msg}`)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: t('sendNotification.SendNotificationSuccessTitle'),
          showConfirmButton: true,
          confirmButtonText: t('app.OK'),
          timer: 3500
        })
      },
      onError: (error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: t('sendNotification.SendNotificationErrorTitle'),
          showConfirmButton: true,
          confirmButtonText: t('app.OK'),
          timer: 3500
        });
      }
    }
  );

  
  const { mutate: sendNotificationToAllRoads } = useMutation(
    ['sendNotificationToAllRoads'],
    async () => {
      return await apiClient.sendAvisTempeteToAllRoad(enterprise, idEmailMessage);
    },
    {
      onSuccess: ({data}) => {
        handleAction(`${data.msg}`)
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: t('sendNotification.SendNotificationSuccessTitle'),
          showConfirmButton: true,
          confirmButtonText: t('app.OK'),
          timer: 3500
        })
      },
      onError: (error) => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: t('sendNotification.SendNotificationErrorTitle'),
          showConfirmButton: true,
          confirmButtonText: t('app.OK'),
          timer: 3500
        });
      }
    }
  );


  const handleSendNotification = (roadId) => {
    Swal.fire({
      icon: 'warning',
      title: t('sendNotification.NotificationConfirmationTitle'),
      text: t('sendNotification.NotificationConfirmationTitleText'),
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.dark,
      cancelButtonText: t('app.Cancel'),
      confirmButtonText: t('app.Yes'),
    }).then((result) => {
      if(result.value) {
        sendNotification(roadId)
      }}
    )
  }

  const handleSendNotificationToAll = () => {
    Swal.fire({
      icon: 'warning',
      title: t('sendNotification.NotificationConfirmationTitle'),
      text: t('sendNotification.NotificationConfirmationTitleText'),
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.dark,
      cancelButtonText: t('app.Cancel'),
      confirmButtonText: t('app.Yes'),
    }).then((result) => {
      if(result.value) {
        sendNotificationToAllRoads()
      }}
    )
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="secondary" size={40}/>
      </Backdrop>
      <NotificationAlert openNotification={errors?.msg} type='error' message={t('roads.ErrorFetchingData')}/>
      <Container maxWidth="xl" sx={{pt:5, pb:5}}>
        <TitleWithBack pageTitle={t('sendNotification.SelectRoad')} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <CardSendNotification id={0} title={'app.All'} handleSendNotification={() => handleSendNotificationToAll()} />
          </Grid>
          {
            roads?.map((item, index) => {
              return (
                <Grid key={index} item xs={12} md={6} xl={4}>
                  <CardSendNotification id={item.id} title={item.roadName} handleSendNotification={handleSendNotification} />
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </>
  );
}

export default SelectAvisRoadPage;
