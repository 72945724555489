import {ProtectedContextProvider} from "../../contexts/protectedContext";
import {Container} from "@mui/material";
import {BackToTop, Header, myStyles} from "components";

export default function ProtectedRouteContainer({ element }) {
  return (
        <>
            <ProtectedContextProvider>
                <ProtectedRoute element={element} />
            </ProtectedContextProvider>
        </>
    )
}

function ProtectedRoute({ element }) {
    return (
        <>
            <Header />
            <Container maxWidth="true" disableGutters className="application-container" sx={myStyles.protectedContainer}>
                {element}
            </Container>
            <BackToTop />
            {/*<Footer />*/}
        </>
    )
}