import React from 'react';
import MUIDataTable from "mui-datatables";

function BaseGrid({gridColumn, gridData}) {
    const options = {
        filter: false,
        filterType: "dropdown",
        responsive: 'vertical',
        download: false,
        print: false,
        viewColumns: false,
        rowsPerPage:100,
        selectableRows: 'none',
        tableBodyHeight: '100%',
        tableBodyMaxHeight: '100%',
        rowsPerPageOptions: []
    };

    return (
        <MUIDataTable
            data={gridData || []}
            columns={gridColumn}
            options={options}
        />

    );
}

export default BaseGrid;