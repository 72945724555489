import React from 'react';
import { EnterpriseSelectionCard} from '../../index'


function Avis() {
	return (
		<EnterpriseSelectionCard url="avis" />
	);
}
export default Avis;
