import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Autocomplete,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../theme";
import {Controller, useForm} from "react-hook-form";
import {AlertBox, FormHelper, myStyles} from "components/index";
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import {useMutation} from "react-query";
import {useLogContext} from "../../contexts/logContext";

function ModalCrudRoad({open, handleClose, isEditMode, enterprise, selectedRoad, handleUpdateRoads}) {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const [errors, setErrors] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { isLoading: isLoadingCrud, mutate: crudRoad } = useMutation(
        async (selectedRoad) => {
            return await apiClient.crudRoad(selectedRoad);
        },
        {
            onSuccess: ({data}) => {
                isEditMode ? handleAction(`Modification de la route => ${data[0].roadName}`) : handleAction(`Création de la route => ${data[0].roadName}`)
                handleUpdateRoads()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const { control,reset, handleSubmit, setValue } = useForm({
        reValidateMode: "onBlur",
        defaultValues: {
            enterprise: enterprise.filter((ent) => ent.id === selectedRoad?.enterpriseFK)[0]?.name || enterprise[0]?.name,
            roadName: selectedRoad?.roadName || "",
            roadDescription: selectedRoad?.roadDescription || "",
        }
    });

    useEffect(() => {
        reset()
        if (isEditMode) {
            setValue('enterprise', enterprise.filter((ent) => ent.id === selectedRoad?.enterpriseFK)[0]?.name)
            setValue('roadName', selectedRoad.roadName)
            setValue('roadDescription', selectedRoad.roadDescription)
        }else{
            setValue('enterprise', enterprise[0]?.name)
        }
    }, [open]);

    const handleCloseDialog = () => {
        reset();
        setErrors({});
        handleClose();
    }

    const handleOnSubmit = (values) => {
         crudRoad({
                id: isEditMode ? selectedRoad?.id : null,
                enterpriseFK : enterprise.filter(m => m.name === values.enterprise)[0]?.id || enterprise.filter(e => e.name === values.enterprise.name)[0]?.id,
                roadName: values.roadName,
                roadDescription: values.roadDescription,
            }
        );
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseDialog}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-crud"
            aria-describedby="scroll-dialog-description-crud"
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h3" sx={{fontWeight:'bold'}}>{ isEditMode ? t('roads.EditRoad') : t('roads.AddRoad')}</Typography>
                    <HighlightOffIcon sx={myStyles.iconTitle} onClick={() => {
                        handleCloseDialog()
                    }}/>
                </Stack>
            </DialogTitle>
            <DialogContent dividers={true}>
                {
                    errors?.hasError && <AlertBox type={"error"} message={t('roads.ErrorAddRoad')}/>
                }
                <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                    <Controller
                        control={control}
                        name="enterprise"
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                options={enterprise}
                                getOptionLabel={option => option.name || option}
                                onChange={(event, values) => onChange(values)}
                                value={value}
                                renderInput={(params) => (
                                    <BaseTextField
                                        {...params}
                                        fullWidth
                                        variant="outlined"
                                        label={t('roads.Enterprise')}
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="roadName"
                        rules={{
                            required: true
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label={t('roads.Name')}
                                error={error !== undefined}
                                helperText={error ? t(FormHelper.required[error.type]) : ""}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="roadDescription"
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label={t('roads.Description')}
                            />
                        )}
                    />
                    <Button fullWidth variant="contained"  size="large" type="submit">
                        {t('app.Save')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ModalCrudRoad;
