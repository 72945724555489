import React, {useState} from 'react';
import {Backdrop, CircularProgress, Container, Grid, Typography} from "@mui/material";
import {useQuery} from "react-query";
import apiClient from "../../../services/apiClient";
import {useParams} from "react-router-dom";
import {myStyles} from "../../index";
import AlertBox from "../../alert/AlertBox";

function Unsubscribe() {
	const [errors, setErrors] = useState(false);
	const [success, setSuccess] = useState(false);
	const { id } = useParams()

	const { isLoading, refetch: unsubscribe } = useQuery(
		['unsubscribeEmailForUser'],
		async () => {
			return await apiClient.unsubscribeEmailForUser(id);
		},
		{
			enabled: true,
			onSuccess: () => {
				setSuccess( true)
			},
			onError: (error) => {
				setErrors( true)
			}
		}
	);

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={false}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<Container maxWidth="lg" sx={myStyles.loginContainer}>
				<Grid container alignItems="center" justifyContent="space-between">
					<Grid item xs={12}>
						<Typography variant="h4" sx={{textAlign:'center', mb:5}}>Désactivation des alertes par courriel.</Typography>
					</Grid>
					<Grid item xs={12}>
						{
							!isLoading && success && <AlertBox type="success" message={"Vous avez été retirer des alertes par courriel du système de notification de déneigement. Merci"}/>
						}
						{
							!isLoading && errors && <AlertBox type="error" message={"Une erreur c'est produite lors de la désactivation des alertes par courriel. Merci de contacter le service de déneigement."}/>
						}
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" sx={{textAlign:'center', mt:5}}>Pour tout problèmes ou commentaires, veuillez communiquer avec le numéro sur votre contrat. </Typography>
					</Grid>
				</Grid>
			</Container>

		</>
	);
}

export default Unsubscribe;
