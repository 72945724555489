import React from 'react';
import {Box, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {myStyles} from "../index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Title({handleAdd, pageTitle, iconTooltip, noAdd = false}) {
    const {t} = useTranslation();

    const handleBack = () => {
        window.history.back();
    }

    return (
        <Stack
            direction="row"
            justifyContent={noAdd ? "flex-start" : "space-between"}
            alignItems="center"
            spacing={2}
        >
            <Tooltip title={t('app.Back')} >
                <IconButton onClick={() => handleBack()}>
                    <ArrowBackIcon  sx={myStyles.iconTitle}/>
                </IconButton>
            </Tooltip>
            <Typography variant="h2" sx={{fontWeight:'bold', mb:3}}>{t(pageTitle)}</Typography>
            {
                noAdd ? null : (
                    <Tooltip title={t(iconTooltip)} >
                        <IconButton onClick={() => handleAdd()}>
                            <AddCircleOutlineIcon  sx={myStyles.iconTitle}/>
                        </IconButton>
                    </Tooltip>
                )
            }
        </Stack>
    );
}

export default Title;