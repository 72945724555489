import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import apiClient from "../../../services/apiClient";
import {Backdrop, CircularProgress, Container, Grid} from "@mui/material";
import {CardSelectAction, NotificationAlert, TitleWithBack} from "components";

function SelectTutorialsNotificationPage(props) {
	const {t} = useTranslation();
	const {enterprise} = useParams()
	const [notifications, setNotifications] = useState([]);
	const [errors, setErrors] = useState({});

	const { isLoading: isLoadingNotification, refetch: fetchTutorialsNotifications } = useQuery(
		['fetchTutorialsNotifications'],
		async () => {
			return await apiClient.fetchTutorialsNotifications();
		},
		{
			enabled: true,
			onSuccess: ({data}) => {
				setNotifications(data)
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoadingNotification}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<NotificationAlert openNotification={errors?.msg} type='error' message={t('sendNotification.ErrorFetchingData')}/>
			<Container maxWidth="xl" sx={{pt: 5, pb: 5}}>
				<TitleWithBack pageTitle={t('sendNotification.SelectNotification')} />
				<Grid container spacing={2}>
					{
						notifications?.map((item, index) => {
							return (
								<Grid key={index} item xs={12} md={6} xl={4}>
									<CardSelectAction id={index} title={item.title} navigateTo={`/general/${enterprise}/${item.id}`} />
								</Grid>
							)
						})
					}
				</Grid>
			</Container>
		</>
	);
}

export default SelectTutorialsNotificationPage;