import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Autocomplete,
    Box, Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, FormControlLabel,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../theme";
import {Controller, useForm} from "react-hook-form";
import {AlertBox, FormHelper, myStyles} from "components/index";
import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import {useMutation} from "react-query";
import {useProtectedContext} from "../../contexts/protectedContext";
import {useLogContext} from "../../contexts/logContext";

function ModalCrudTutorials({open, handleClose, isEditMode, selectedNotification, clientType,handleUpdateNotifications}) {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const [errors, setErrors] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [recipient, setRecipient] = useState("");

    const { isLoading: isLoadingCrud, mutate: crudNotification } = useMutation(
        async (selectedNotification) => {
            return await apiClient.crudTutorial(selectedNotification);
        },
        {
            onSuccess: ({data}) => {
                isEditMode ? handleAction(`Modification du tutoriel => ${data[0].title}`) : handleAction(`Création du tutoriel => ${data[0].title}`)
                handleUpdateNotifications()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const { control,reset, handleSubmit, setValue } = useForm({
        reValidateMode: "onBlur",
        defaultValues: {
            notificationTitle: selectedNotification?.title || "",
            notificationMessage: selectedNotification?.message || "",
            notificationrecipientId: selectedNotification?.clientTypeFK || 1,
        }
    });

    useEffect(() => {
        clientType.map((item) => {
            item.label = item.type
        })
        reset()
        if (isEditMode) {
            setValue('notificationTitle', selectedNotification?.title)
            setValue('notificationMessage', selectedNotification?.message)
            setValue('notificationrecipientId', selectedNotification?.clientTypeFK)

        }
        else{
            setValue('notificationTitle', "")
            setValue('notificationMessage', "")
            setValue('notificationrecipientId', "")

        }
    }, [open]);

    const handleCloseDialog = () => {
        reset();
        setErrors({});
        handleClose();
    }

    const handleOnSubmit = (values) => {
        crudNotification({
            id: isEditMode ? selectedNotification.id : null,
            title: values?.notificationTitle || selectedNotification?.title,
            message: values?.notificationMessage || selectedNotification?.message,
            clientTypeFK: recipient || selectedNotification?.clientTypeFK
        });
    }

    const onRecipientChange = (e,value) => {
        setRecipient(value.id)
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseDialog}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-crud"
            aria-describedby="scroll-dialog-description-crud"
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h3" sx={{fontWeight:'bold'}}>{ isEditMode ? t('message.EditMessage') : t('message.AddMessage')}</Typography>
                    <HighlightOffIcon sx={myStyles.iconTitle} onClick={() => {
                        handleCloseDialog()
                    }}/>
                </Stack>
            </DialogTitle>
            <DialogContent dividers={true}>
                {
                    errors?.hasError && <AlertBox type={"error"} message={t('notifications.ErrorAddNotifications')}/>
                }
                <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                   <Controller
                        control={control}
                        name="notificationTitle"
                        rules={{
                            required: true
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label={t('message.Title')}
                                error={error !== undefined}
                                helperText={error ? t(FormHelper.required[error.type]) : ""}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="notificationMessage"
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                fullWidth
                                multiline
                                rows={6}
                                variant="outlined"
                                label={t('message.Message')}
                            />
                        )}
                    />
                    <Box sx={{ pt:0, pb:5 }}>
                        <Controller
                            control={control}
                            name="notificationrecipientId"
                            variant="outlined"
                            rules={{
                                required: false
                            }}
                            render={({field, fieldState: { error } }) => (
                                <Autocomplete
                                    value={clientType.find(type => type.id === selectedNotification?.clientTypeFK)?.type}
                                    options={clientType}
                                    fullWidth
                                    onChange={(e,value) => {
                                        onRecipientChange(e,value);
                                    }}
                                    renderInput={(params) => <BaseTextField {...params} label={t('message.Recipients')} />}
                                />

                            )
                            }
                        />
                    </Box>

                    <Button fullWidth variant="contained"  size="large" type="submit">
                        {t('app.Save')}
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ModalCrudTutorials;
