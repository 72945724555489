import {createContext, useContext} from "react";
import {useAuthContext} from "./authContext";
import {useMutation} from "react-query";
import apiClient from "../services/apiClient";

const LogContext = createContext(null)

export const LogContextProvider = ({ children }) => {
	const { user } = useAuthContext();

	const { mutate: addLog } = useMutation(
		async (log) => {
			return await apiClient.addLog(log);
		},
	);
	const handleAction = (action) => {
		const log = {
			action: action,
			user: user?.fullName || "Application",
		}
		addLog(log)
	}

	const logValue = {handleAction}

	return (
		<LogContext.Provider value={logValue}>
			{children}
		</LogContext.Provider>
	)
}

export const useLogContext = () => useContext(LogContext)

