import { useTranslation } from 'react-i18next'
import { useLogContext } from '../../../contexts/logContext'
import { useProtectedContext } from '../../../contexts/protectedContext'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import apiClient from '../../../services/apiClient'
import Swal from 'sweetalert2'
import theme from '../../../theme'
import { Backdrop, CircularProgress, Container, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { getSelectedData } from '../../../utils/utils'
import { BaseGrid, ModalCrudEmail, myStyles, NotificationAlert, Title } from '../../index'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

function GestionAvis() {
  const {t} = useTranslation();
  const { handleAction } = useLogContext()
  const { type } = useProtectedContext();
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [updated, setUpdated] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [clientType, setClientType] = useState([]);

  const {mutate : fetchAllClientTypes} = useQuery(
    ['fetchAllClientTypes'],
    async () => {
      return await apiClient.fetchAllClientTypes();
    },
    {
      enabled: true,
      onSuccess: ({data}) => {
        setClientType(data)
        console.log('data', data)
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const { isLoading: isLoadingNotification, refetch: fetchAllNotifications } = useQuery(
    ['fetchAllNotifications'],
    async () => {
      return await apiClient.fetchAllEmailNotifications();
    },
    {
      enabled: true,
      onSuccess: ({data}) => {
        console.log('data', data)
        setNotifications(data)
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );


  const { mutate: deleteNotification} = useMutation(
    async (notification) => {
      return await apiClient.deleteEmailNotification(notification);
    },
    {
      onSuccess: () => {
        setUpdateDelete(true)
        handleAction(`Suppression du courriel template => ${selectedNotification.title}`)
        fetchAllNotifications()
      },
      onError: (error) => {
        setErrors({msg: true})
      }
    }
  );

  const handleAddNotification = () => {
    setIsEditMode(false)
    setOpen(true)
    setUpdated(false)
  }

  const handleEditNotification = (notification) => {
    setSelectedNotification(notification)
    setIsEditMode(true)
    setOpen(true)
    setUpdated(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUpdateNotifications = () => {
    setUpdated(true)
    fetchAllNotifications()
    handleClose()

  }

  const handleDeleteNotification = (notification) => {
    //Get ID from selected notification
    setSelectedNotification(notification)
    setUpdateDelete(false)
    Swal.fire({
      icon: 'warning',
      title: t('messageAvis.DeleteTitle'),
      text: t('messageAvis.DeleteWarningText'),
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.dark,
      cancelButtonText: t('app.Cancel'),
      confirmButtonText: t('app.Yes'),
    }).then((result) => {
      if(result.value) {
        deleteNotification(notification.id)
      }}
    )
  }

  const gridNotificationColumns = [
    {
      name: "title",
      label: t('messageAvis.Title'),
    },
    {
      name: "message",
      label: t('messageAvis.Message'),
    },
    {
      name: "clientType",
      label: t('message.Recipients'),
      options: {
        customBodyRender: (value) => (
          <>
            {value?.type}
          </>
        )
      }
    },
    {
      name: "id",
      label: " ",
      options: {
        customBodyRender: (value) => (
          <>
            <Tooltip title={t('app.Edit')}>
              <EditIcon fontSize="small" onClick={() => handleEditNotification(getSelectedData(value, notifications))} sx={myStyles.gridIcons}/>
            </Tooltip>
            <Tooltip title={t('app.Delete')}>
              <DeleteForeverIcon background="#ff0000" fontSize="small" onClick={() => handleDeleteNotification(getSelectedData(value, notifications))} sx={myStyles.gridDeleteIcons}/>
            </Tooltip>
          </>
        )
      }
    },
  ];

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingNotification}
      >
        <CircularProgress color="secondary" size={40}/>
      </Backdrop>
      <NotificationAlert openNotification={updated} type='success' message={t('messageAvis.CompletedSuccess')}/>
      <NotificationAlert openNotification={updateDelete} type='success' message={t('messageAvis.DeleteCompletedSuccess')}/>
      <NotificationAlert openNotification={errors?.msg} type='error' message={t('messageAvis.ErrorFetchingData')}/>
      <Container maxWidth="xl" sx={{pt:5, pb:5}}>
        <ModalCrudEmail open={open} handleClose={handleClose} isEditMode={isEditMode}  selectedNotification={selectedNotification} handleUpdateNotifications={handleUpdateNotifications}  clientType={clientType}/>
        <Title handleAdd={handleAddNotification} pageTitle={'messageAvis.MessageTitle'} iconTooltip={'messageAvis.AddMessage'}/>
        <BaseGrid gridData={notifications} gridColumn={gridNotificationColumns} />
      </Container>
    </>
  );
}

export default GestionAvis;
