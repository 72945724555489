import React from 'react';

function PageDoesNotExist(props) {
	return (
		<div>
			erreur page n'existe pas
		</div>
	);
}

export default PageDoesNotExist;