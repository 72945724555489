import React from 'react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

function ReviewInfos ({ handleBack }) {
  const {t} = useTranslation();

  const handleRestart = () => {
    window.location.reload();
  }

  return (
    <>
      <Typography variant="h2"
                  sx={{ mt: 2 }}>{t('gestionLocataires.Thanks')}</Typography>
      <Typography variant="body1"
                  sx={{ mt: 2 }}>{t('gestionLocataires.ThanksText')}</Typography>
      <div className="btn-component">
        <Button className="next-btn" onClick={handleBack} variant="contained" size="meddium" type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Back')}
        </Button>
        <Button className="next-btn" onClick={handleRestart} variant="contained" size="meddium" type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.Finish')}
        </Button>
        <Button className="next-btn" onClick={handleRestart} variant="contained" size="meddium"
                type="submit"
                sx={{ mt: 1, mb: 1 }}>
          {t('app.RestartGestionLocataire')}
        </Button>
      </div>
    </>
  )
}

export default ReviewInfos
