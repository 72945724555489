import React, { useEffect } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { BaseTextField } from '../../textfield/StyledTextField'
import { AlertBox, FormHelper } from '../../index'
import { useTranslation } from 'react-i18next'

function ContractStep ({ handleOnSubmit, owner, errors, hasNotAgreeError }) {
  const {t} = useTranslation();
  const { control,reset, handleSubmit, setValue, watch  } = useForm({
    reValidateMode: "onBlur",
		defaultValues: {
			clientContract: "",
			clientReference: "",
		}
  });

	const onSubmit = (values) => {
		owner.clientContractNumber = values.clientContract;
		owner.clientReference = values.clientReference;
		handleOnSubmit(values);
	};

	useEffect(() => {
		if (owner.clientContractNumber) {
			setValue('clientContract', owner.clientContractNumber);
		}
		if (owner.clientReference) {
			setValue('clientReference', owner.clientReference);
		}
	}, [owner, setValue]);
  
  return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sx={{ textAlign: 'left' }}>
					<Typography variant="h5"
											sx={{ fontWeight: 'bold', mb: 0 }}>{t("gestionLocataires.EnterContract")}</Typography>
					<Typography variant="body2" sx={{ color: 'text.secondary' }}>{t("gestionLocataires.EnterContractDesc")}</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="clientContract"
						rules={{
							required: true
						}}
						render={({ field, fieldState: { error } }) => (
							<BaseTextField
								{...field}
								fullWidth
								type="text"
								variant="outlined"
								label={t('gestionLocataires.Contract')}
								error={error !== undefined}
								sx={{ mb: 0 }}
								helperText={error ? t(FormHelper.required[error.type]) : ""}
								onChange={(e) => {
									field.onChange(e);
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Controller
						control={control}
						name="clientReference"
						rules={{
							required: true
						}}
						render={({ field, fieldState: { error } }) => (
							<BaseTextField
								{...field}
								fullWidth
								type="text"
								variant="outlined"
								label={t('gestionLocataires.ClientReference')}
								error={error !== undefined}
								sx={{ mb: 0 }}
								helperText={error ? t(FormHelper.required[error.type]) : ""}
								onChange={(e) => {
									field.onChange(e);
								}}
							/>
						)}
					/>
				</Grid>
				{
					errors?.msg && (
						<div className="contrat-step-error">
							<AlertBox type={"error"} message={t('gestionLocataires.ErrorFetchingContractData')}/>
						</div>
					)
				}
				{
					hasNotAgreeError && (
						<div className="contrat-step-error">
							<AlertBox type={"warning"} message={t('gestionLocataires.ErrorAgreePolicies')}/>
						</div>
					)
				}
			</Grid>
			<div className="btn-component">
				{
					<Button className="step-one-next-btn" onClick={handleSubmit(onSubmit)} variant="contained"  size="meddium" type="submit" sx={{ mt:1, mb:1}}>
						{t('app.Next')}
					</Button>
				}
			</div>
		</>
	)
}

export default ContractStep
