import axios from 'axios'

class ApiClient {
  constructor (remoteHostUrl) {
    this.remoteHostUrl = remoteHostUrl
    this.tokenName = 'profiljardins'
    this.token = null
  }

  setToken (token) {
    this.token = token
    localStorage.setItem(this.tokenName, token)
  }

  async request ({ endpoint, method = 'GET', data = {} }) {
    const url = `${this.remoteHostUrl}/${endpoint}`
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: this.token ? `Bearer ${this.token}` : '',
    }

    try {
      const res = await axios({ url, method, data, headers })
      return { data: res.data, error: null }
    } catch (error) {
      console.error('APIclient.makeRequest.error:')
      console.error({ errorResponse: error.response })
      throw (error?.response?.data?.error?.message || String(error))
    }
  }

  // ----------------------------------------
  // USER
  // ----------------------------------------
  async fetchUserFromToken () {
    return await this.request({ endpoint: `user/me`, method: `GET` })
  }

  // ----------------------------------------
  // Email Notification
  // ----------------------------------------
  async fetchAllEmailNotifications () {
    return await this.request({ endpoint: `email/`, method: `GET` })
  }

  async sendAvisTempete (roadId, notification, enterpriseId) {
    return await this.request({
      endpoint: `email/sendavis`,
      method: `POST`,
      data: { roadId, notification, enterpriseId }
    })
  }

  async sendAvisTempeteToAllRoad (enterpriseId, notification) {
    return await this.request({ endpoint: `email/sendallavis`, method: `POST`, data: { notification, enterpriseId } })
  }

  async deleteEmailNotification (notificationId) {
    return await this.request({ endpoint: `email/${notificationId}`, method: `DELETE` })
  }

  async crudEmailNotification (notification) {
    return await this.request({ endpoint: `email/`, method: `POST`, data: notification })
  }

  // ----------------------------------------
  // NOTIFICATIONS
  // ----------------------------------------
  async fetchAllNotifications () {
    return await this.request({ endpoint: `notification/`, method: `GET` })
  }

  async crudNotification (notification) {
    return await this.request({ endpoint: `notification/`, method: `POST`, data: notification })
  }

  async deleteNotification (notificationId) {
    return await this.request({ endpoint: `notification/${notificationId}`, method: `DELETE` })
  }

  async sendNotification (roadId, notificationId) {
    return await this.request({ endpoint: `notification/send`, method: `POST`, data: { roadId, notificationId } })
  }

  async sendNotificationToAllRoad (enterprise, notificationId) {
    return await this.request({
      endpoint: `notification/sendall`,
      method: `POST`,
      data: { enterprise, notificationId }
    })
  }

  // ----------------------------------------
  // ROADS
  // ----------------------------------------
  async fetchAllRoads () {
    return await this.request({ endpoint: `road/`, method: `GET` })
  }

  async fetchAllRoadsByEnterpriseId (enterpriseId) {
    return await this.request({ endpoint: `road/enterprise/${enterpriseId}`, method: `GET` })
  }

  async crudRoad (road) {
    return await this.request({ endpoint: `road/`, method: `POST`, data: road })
  }

  async deleteRoad (roadId) {
    return await this.request({ endpoint: `road/delete/${roadId}`, method: `DELETE` })
  }

  // ----------------------------------------
  // LOGS
  // ----------------------------------------
  async addLog (log) {
    return await this.request({ endpoint: `logs/addlog`, method: `POST`, data: log })
  }

  async fetchAllLogs () {
    return await this.request({ endpoint: `logs/`, method: `GET` })
  }

  // ----------------------------------------
  // CLIENTS
  // ----------------------------------------
  async fetchAllClients () {
    return await this.request({ endpoint: `clients/`, method: `GET` })
  }

  async crudClients (client) {
    return await this.request({ endpoint: `clients/`, method: `POST`, data: client })
  }

  async deleteClient (id) {
    return await this.request({ endpoint: `clients/${id}`, method: `DELETE` })
  }

  async deleteTenant (id) {
    return await this.request({ endpoint: `tenants/${id}`, method: `DELETE` })
  }

  async unsubscribeEmailForUser (clientId) {
    return await this.request({ endpoint: `clients/unsubscribe`, method: `POST`, data: { clientId } })
  }

  // ----------------------------------------
  // CLIENTS - Gestion Locataire
  // ----------------------------------------

  async fetchOwner (contract) {
    return await this.request({
      endpoint: `clients/gestion/${contract.clientContract}/${contract.clientReference}`,
      method: `GET`
    })
  }

  async crudTenantsList (tenantsList) {
    return await this.request({ endpoint: `clients/gestion/tenants/`, method: `POST`, data: tenantsList })
  }

  async crudTenantsEmailList (tenantsList) {
    return await this.request({ endpoint: `clients/gestion/tenantsemail/`, method: `POST`, data: tenantsList })
  }

  async crudClientsInfos (owner) {
    return await this.request({ endpoint: `clients/gestion/`, method: `POST`, data: owner })
  }

  async deleteTenantGestion (id) {
    return await this.request({ endpoint: `tenants/gestion/${id}`, method: `DELETE` })
  }

  async deleteTenantEmailGestion (id) {
    return await this.request({ endpoint: `tenantsemail/${id}`, method: `DELETE` })
  }

  async addLogGestionLocataire (log) {
    return await this.request({ endpoint: `logs/addloglocataire`, method: `POST`, data: log })
  }

  // ----------------------------------------
  // CLIENTS Type
  // ----------------------------------------

  async fetchAllClientTypes () {
    return await this.request({ endpoint: `clients/type`, method: `GET` })
  }

  // ----------------------------------------
  // ENTERPRISE
  // ----------------------------------------
  async fetchEnterprise () {
    return await this.request({ endpoint: `enterprise/`, method: `GET` })
  }

  // ----------------------------------------
  // TUTORIALS NOTIFICATIONS (Avis Generale)
  // ----------------------------------------

  async crudTutorial (tutorial) {
    return await this.request({ endpoint: `tutorial/`, method: `POST`, data: tutorial })
  }

  async deleteTutorialsNotification (notificationId) {
    return await this.request({ endpoint: `tutorial/${notificationId}`, method: `DELETE` })
  }

  async fetchTutorialsNotifications () {
    return await this.request({ endpoint: `tutorial/`, method: `GET` })
  }

  async sendTutorialsNotification (roadId, tutorialId) {
    return await this.request({ endpoint: `tutorial/send`, method: `POST`, data: { roadId, tutorialId } })
  }

  async sendTutorialsNotificationToAllRoads (enterpriseId, tutorialId) {
    return await this.request({ endpoint: `tutorial/sendall`, method: `POST`, data: { enterpriseId, tutorialId } })
  }

  // ----------------------------------------
  // IMPORTATION
  // ----------------------------------------
  async importFile (file) {
    return await this.request({ endpoint: `importation/pushFile`, method: `POST`, data: { file } })
  }

  async crudImportation (client) {
    return await this.request({ endpoint: `importation/crudimportation`, method: `POST`, data: { client } })
  }

  async fetchAllErrors () {
    return await this.request({ endpoint: `importation/fetchallerrors`, method: `GET` })
  }

  async deleteErrorTenant (tenantId) {
    return await this.request({ endpoint: `importation/deleteerrortenant`, method: `DELETE`, data: { tenantId } })
  }

  async deleteErrorClient (client) {
    return await this.request({ endpoint: `importation/deleteerrorclient`, method: `DELETE`, data: { client } })
  }
}

export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL)
