import React, {useState,ChangeEvent} from 'react';
import {
    Backdrop, CircularProgress, Container, Tooltip,
    Button,
    Grid,
    Typography,
    Input,
} from "@mui/material";

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import apiClient from "../../../services/apiClient";
import {BaseGrid, FormHelper, myStyles, Title, ModalCrudImportation, NotificationAlert} from "components";
import {useTranslation} from "react-i18next";
import {useProtectedContext} from "../../../contexts/protectedContext";
import {useLogContext} from "../../../contexts/logContext";
import EditIcon from "@mui/icons-material/Edit";
import {getSelectedData} from "../../../utils/utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {useMutation, useQuery} from "react-query";
import Swal from "sweetalert2";
import theme from "../../../theme";

function Importation() {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const { type } = useProtectedContext();
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
    const [selectedClient, setSelectedClient] = useState({});
    const [updated, setUpdated] = useState(false);
    const [roads, setRoads] = useState([]);
    const [updateDelete, setUpdateDelete] = useState(false);


    const [clients, setClient] = useState([]);
    const [file, setFile] = useState(null);
    const [received, setReceived] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const { refetch: fetchAllErrors } = useQuery(
        ['fetchAllErrors'],
        async () => {
            setLoading(true)
            return await apiClient.fetchAllErrors();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setLoading(false)
                setClient(data)
            },
            onError: (error) => {
                setLoading(false)
                setErrors({msg: true})
            }
        }
    );

    const {mutate : fetchAllRoads} = useQuery(
        ['fetchAllRoads'],
        async () => {
            return await apiClient.fetchAllRoads();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setRoads(data)
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const { mutate: deleteErrorClient} = useMutation(
        async (clientId,clientReference) => {
            return await apiClient.deleteErrorClient(clientId,clientReference);
        },
        {
            onSuccess: () => {
                setUpdateDelete(true)
                handleAction(`Suppression du client => ${selectedClient.clientName}`)
                fetchAllErrors()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );
    const handleUploadClick = () => {
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)
        fetch('http://localhost:5010/api/importation/pushFile', {
            method: 'POST',
            body: formData,
            headers: {
                'content-length': `${file.size}`,
                "Authorization": "Bearer " + localStorage.getItem("profiljardins")
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setReceived(data);
                setLoading(false)
                fetchAllErrors()
            })
    };

    const buildingTypeIcon = (buildingTypeId) => {
        switch (buildingTypeId) {
            case 1:
                return  <HomeIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
            case 2:
                return <BusinessIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
            case 3:
                return <ApartmentIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdateClients = () => {
        setUpdated(true)
        fetchAllErrors()
        handleClose()
    }

    const handleEditClient = (client) => {
        setSelectedClient(client)
        setIsEditMode(true)
        setOpen(true)
        setUpdated(false)
    }

    const handleDeleteClient = (client) => {
        setSelectedClient(client)
        setUpdateDelete(false)
        Swal.fire({
            icon: 'warning',
            title: t('client.DeleteTitle'),
            text: t('client.DeleteWarningText'),
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.dark,
            cancelButtonText: t('app.Cancel'),
            confirmButtonText: t('app.Yes'),
        }).then((result) => {
            if(result.value) {
                deleteErrorClient(client)
            }}
        )
    }

    const gridClientColumns = [
        {
            name: "clientName",
            label: t('client.Name'),
        },
        {
            name: "telephoneNumber",
            label: t('client.Phone'),

        },
        {
            name: "cellphoneNumber",
            label: t('client.Cellphone'),
        },
        {
            name: "address",
            label: t('client.Address'),
        },
        {
            name: "contract",
            label: t('client.Contract'),
        },
        {
            name: "reference",
            label: t('client.Reference'),
        },
        {
            name: "buildingTypeId",
            label: t('client.BuildingType'),
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={value === 1 ?('Résidentiel') : value === 2 ? t('Commercial') : t('Multi-Logement')}>
                            <div>
                                {buildingTypeIcon(value)}
                            </div>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            name: "id",
            label: " ",
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={t('app.Edit')}>
                            <EditIcon fontSize="small" onClick={() => handleEditClient(getSelectedData(value, clients))} sx={myStyles.gridIcons}/>
                        </Tooltip>
                        <Tooltip title={t('app.Delete')}>
                            <DeleteForeverIcon background="#ff0000" fontSize="small" onClick={() => handleDeleteClient(getSelectedData(value, clients))} sx={myStyles.gridDeleteIcons}/>
                        </Tooltip>
                    </>
                )
            }
        },
    ];

    return (
        <>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={updated} type='success' message={t('client.CompletedSuccess')}/>
            <NotificationAlert openNotification={updateDelete} type='success' message={t('client.DeleteCompletedSuccess')}/>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('client.ErrorFetchingData')}/>
            <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                <Title pageTitle={'importation.Title'}/>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <label>
                        <FileUploadIcon sx={myStyles.iconsDefault}/>
                        <Input type="file" accept=".xls,.xlsx" onChange={handleFileChange} sx={{display: "none"}}/>
                    </label>
                </Grid>
                <Grid item xs={4}>
                    <Typography>{file && `${file.name}`}</Typography>
                </Grid>
                    <Grid item xs={4}>
                    <Button onClick={handleUploadClick}>{t('import.UploadFile')}</Button>
                </Grid>
            </Grid>
                <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography sx={{pt:5}}>{file && `${file.name}` || t('import.NoFileSelected')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                    <CheckCircleIcon background="#ff0000" sx={myStyles.iconsSuccess}/>
                                <Typography>{received || 0}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                    <ErrorIcon sx={myStyles.iconsError}/>
                                <Typography>{clients.length || 0}</Typography>
                            </Grid>
                </Grid>
                <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                    <ModalCrudImportation open={open} handleClose={handleClose} isEditMode={isEditMode} selectedClient={selectedClient} roads={roads} handleUpdateClients={handleUpdateClients}/>

                    <BaseGrid gridData={clients} gridColumn={gridClientColumns}/>
                </Container>
            </Container>
        </>
    );
}

export default Importation;