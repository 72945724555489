import {createContext, useContext, useEffect, useState} from "react";
import {Login} from "../components";
import {useAuthContext} from "./authContext";
import {Backdrop, CircularProgress} from "@mui/material";
import apiClient from "../services/apiClient";
import {useQuery} from "react-query";
import {LogContextProvider} from "./logContext";

const ProtectedContext  = createContext(null)

export const ProtectedContextProvider = ({ children }) => {
    const { initialized, user } = useAuthContext();
    const [enterprise, setEnterprise] = useState({});
    const protectedValue = {enterprise}

    const { refetch: fetchEnterprise } = useQuery(
        ['fetchEnterprise'],
        async () => {
            return await apiClient.fetchEnterprise();
        },
        {
            enabled: initialized,
            onSuccess: ({data}) => {
                const transformedData = data.map((item) => ({
                    id: item.id,
                    name: item.enterpriseName,
                }));

                setEnterprise(transformedData);
            },
        }
    );

    if (initialized && !user?.email) {
        return <Login message="app.MustBeAuthenticated"/>
    }

    if (initialized){
        if (JSON.stringify(enterprise) === '{}') {
            fetchEnterprise()
        } else {
            return (
                <>
                    <ProtectedContext.Provider value={protectedValue}>
                        <LogContextProvider>
                            {children}
                        </LogContextProvider>
                    </ProtectedContext.Provider>
                </>
            )
        }

    }else {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

}

export const useProtectedContext = () => useContext(ProtectedContext)