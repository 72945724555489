import React from 'react';
import styled from "@emotion/styled";
import {Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import theme from "../../theme";
import {useAuthContext} from "../../contexts/authContext";
import {useTranslation} from "react-i18next";
import Groups3Icon from '@mui/icons-material/Groups3';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PublishIcon from '@mui/icons-material/Publish';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import EmailIcon from '@mui/icons-material/Email';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CampaignIcon from '@mui/icons-material/Campaign';
import DraftsIcon from '@mui/icons-material/Drafts';
import ApartmentIcon from '@mui/icons-material/Apartment';


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
    [theme.breakpoints.down('sm')]: {
        width: 0,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const SideDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
function SideMenu({open, handleDrawer}) {
    const { handleLogout } = useAuthContext()
    const {t} = useTranslation();

    return (
        <SideDrawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawer}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                <ListItemButton component="a" href="/dashboard" >
                    <ListItemIcon>
                        <DashboardIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Dashboard')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/notifications" >
                    <ListItemIcon>
                        <PhoneIphoneIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Notifications')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/messages" >
                    <ListItemIcon>
                        <TextsmsIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Messages')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/roads" >
                    <ListItemIcon>
                        <AgricultureIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Roads')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/general" >
                    <ListItemIcon>
                        <CampaignIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.GeneralNotice')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/generalmessages" >
                    <ListItemIcon>
                        <AnnouncementIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.GeneralMessages')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/avis" >
                    <ListItemIcon>
                        <EmailIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Avis')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/gestionavis" >
                    <ListItemIcon>
                        <DraftsIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.GestionCourriel')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/clients" >
                    <ListItemIcon>
                        <Groups3Icon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Clients')}/>
                </ListItemButton>
                <ListItemButton component="a" href="/gestionlocataires" >
                    <ListItemIcon>
                        <ApartmentIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Gestionlocataires')}/>
                </ListItemButton>
                {/*<ListItemButton component="a" href="/importation" >*/}
                {/*    <ListItemIcon>*/}
                {/*        <PublishIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>*/}
                {/*    </ListItemIcon>*/}
                {/*    <ListItemText primary={t('sideMenu.Importation')}/>*/}
                {/*</ListItemButton>*/}
                <ListItemButton component="a" href="/logs" >
                    <ListItemIcon>
                        <AssignmentIcon sx={{fontSize:'40px', color:'#1d1d1b'}}/>
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Logs')}/>
                </ListItemButton>
            </List>
            <Divider />
            <List>
                <ListItemButton onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon sx={{fontSize:'40px', color:'#1d1d1b'}} />
                    </ListItemIcon>
                    <ListItemText primary={t('sideMenu.Logout')}/>
                </ListItemButton>
            </List>
        </SideDrawer>
    );
}

export default SideMenu;
