import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLogContext} from "../../../contexts/logContext";
import {useMutation, useQuery} from "react-query";
import apiClient from "../../../services/apiClient";
import Swal from "sweetalert2";
import theme from "../../../theme";
import {Backdrop, CircularProgress, Container, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {getSelectedData} from "../../../utils/utils";
import {BaseGrid, ModalCrudTutorials, myStyles, NotificationAlert, Title} from "components";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function TutorialsMessages(props) {
	const {t} = useTranslation();
	const { handleAction } = useLogContext()
	const [errors, setErrors] = useState({});
	const [open, setOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
	const [notifications, setNotifications] = useState([]);
	const [selectedNotification, setSelectedNotification] = useState({});
	const [updated, setUpdated] = useState(false);
	const [updateDelete, setUpdateDelete] = useState(false);
	const [clientType, setClientType] = useState([]);

	const {mutate : fetchAllClientTypes} = useQuery(
		['fetchAllClientTypes'],
		async () => {
			return await apiClient.fetchAllClientTypes();
		},
		{
			enabled: true,
			onSuccess: ({data}) => {
				setClientType(data)
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	const { isLoading: isLoadingNotification, refetch: fetchTutorialsNotifications } = useQuery(
		['fetchTutorialsNotifications'],
		async () => {
			return await apiClient.fetchTutorialsNotifications();
		},
		{
			enabled: true,
			onSuccess: ({data}) => {
				setNotifications(data)
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	const { mutate: deleteTutorialsNotification} = useMutation(
		async (notification) => {
			return await apiClient.deleteTutorialsNotification(notification);
		},
		{
			onSuccess: () => {
				setUpdateDelete(true)
				handleAction(`Suppression du tutoriel => ${selectedNotification.title}`)
				fetchTutorialsNotifications()
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	const handleAddNotification = () => {
		setIsEditMode(false)
		setOpen(true)
		setUpdated(false)
	}

	const handleEditNotification = (notification) => {
		setSelectedNotification(notification)
		setIsEditMode(true)
		setOpen(true)
		setUpdated(false)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleUpdateNotifications = () => {
		setUpdated(true)
		fetchTutorialsNotifications()
		handleClose()

	}

	const handleDeleteNotification = (notification) => {
		//Get ID from selected notification
		setSelectedNotification(notification)
		setUpdateDelete(false)
		Swal.fire({
			icon: 'warning',
			title: t('message.DeleteTitle'),
			text: t('message.DeleteWarningText'),
			showCancelButton: true,
			confirmButtonColor: theme.palette.primary.dark,
			cancelButtonText: t('app.Cancel'),
			confirmButtonText: t('app.Yes'),
		}).then((result) => {
			if(result.value) {
				deleteTutorialsNotification(notification.id)
			}}
		)
	}

	const gridNotificationColumns = [
		{
			name: "title",
			label: t('message.Title'),
		},
		{
			name: "message",
			label: t('message.Message'),
		},
		{
			name: "clientType",
			label: t('message.Recipients'),
			options: {
				customBodyRender: (value) => (
					<>
						{value?.type}
					</>
				)
			}
		},
		{
			name: "id",
			label: " ",
			options: {
				customBodyRender: (value) => (
					<>
						<Tooltip title={t('app.Edit')}>
							<EditIcon fontSize="small" onClick={() => handleEditNotification(getSelectedData(value, notifications))} sx={myStyles.gridIcons}/>
						</Tooltip>
						<Tooltip title={t('app.Delete')}>
							<DeleteForeverIcon background="#ff0000" fontSize="small" onClick={() => handleDeleteNotification(getSelectedData(value, notifications))} sx={myStyles.gridDeleteIcons}/>
						</Tooltip>
					</>
				)
			}
		},
	];

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoadingNotification}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<NotificationAlert openNotification={updated} type='success' message={t('message.CompletedSuccess')}/>
			<NotificationAlert openNotification={updateDelete} type='success' message={t('message.DeleteCompletedSuccess')}/>
			<NotificationAlert openNotification={errors?.msg} type='error' message={t('message.ErrorFetchingData')}/>
			<Container maxWidth="xl" sx={{pt:5, pb:5}}>
				<ModalCrudTutorials open={open} handleClose={handleClose} isEditMode={isEditMode}  selectedNotification={selectedNotification} handleUpdateNotifications={handleUpdateNotifications} clientType = {clientType}/>
				<Title handleAdd={handleAddNotification} pageTitle={'message.MessageTutorialTitle'} iconTooltip={'message.AddTutorialMessage'}/>
				<BaseGrid gridData={notifications} gridColumn={gridNotificationColumns} />
			</Container>
		</>
	);
}

export default TutorialsMessages;
