import React from 'react';
import {IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {myStyles} from "../index";
import {useTranslation} from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function TitleWithBack({pageTitle}) {
    const {t} = useTranslation();

    const handleBack = () => {
        window.history.back();
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{pb:3}}
        >
            <Tooltip title={t('app.Back')} >
                <IconButton onClick={() => handleBack()}>
                    <ArrowBackIcon  sx={myStyles.iconTitle}/>
                </IconButton>
            </Tooltip>
            <Typography variant="h2" sx={{fontWeight:'bold', mb:3}}>{t(pageTitle)}</Typography>
        </Stack>
    );
}

export default TitleWithBack;
