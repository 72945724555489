import React from 'react';
import {CardSelectAction, Title} from "../index";
import {Container, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useProtectedContext} from "../../contexts/protectedContext";

function EnterpriseSelectionCard({url}) {
	const {t} = useTranslation();
	const { enterprise } = useProtectedContext()

	return (
		<Container maxWidth="xl" sx={{pt:5, pb:5}}>
			<Title pageTitle={t('sendNotification.SelectEnterprise')} handleAdd="" noAdd={true} iconTooltip="" />
			<Grid container spacing={2}>
				{
					enterprise?.map((item, index) => {
						return (
							<Grid key={index} item xs={12} md={6}>
								<CardSelectAction id={index} title={item.name} navigateTo={`/${url}/${item.id}`} />
							</Grid>
						)
					})
				}
			</Grid>
		</Container>
	);
}

export default EnterpriseSelectionCard;