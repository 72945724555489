import React from 'react';
import {Card, CardActionArea, Container, Grid, Stack, Typography} from "@mui/material";
import {myStyles} from "components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import Groups3Icon from "@mui/icons-material/Groups3";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CampaignIcon from '@mui/icons-material/Campaign';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmailIcon from '@mui/icons-material/Email';
import DraftsIcon from '@mui/icons-material/Drafts';

function Dashboard() {
    const {t} = useTranslation();
    const navigate = useNavigate()

    return (
        <>
            <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/notifications')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <PhoneIphoneIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Notifications')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/messages')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <TextsmsIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Messages')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/roads')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <AgricultureIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Roads')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/general')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <CampaignIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.GeneralNotice')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/generalmessages')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <AnnouncementIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.GeneralMessages')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/logs')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                    <AssignmentIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Logs')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/avis')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                    <EmailIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Avis')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/gestionavis')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <DraftsIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.GestionCourriel')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/clients')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Groups3Icon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Clients')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card onClick={() => navigate('/gestionlocataires')} sx={myStyles.card}>
                            <CardActionArea sx={{p: 1, minHeight:'200px'}}>
                                <Stack
                                  direction="column"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                    <ApartmentIcon sx={myStyles.cardIconDashboard}/>
                                    <Typography variant={"h2"}>{t('sideMenu.Gestionlocataires')}</Typography>
                                </Stack>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12} md={4}>*/}
                    {/*    <Card onClick={() => navigate('/importation')} sx={myStyles.card}>*/}
                    {/*        <CardActionArea sx={{p: 1, minHeight:'200px'}}>*/}
                    {/*            <Stack*/}
                    {/*                direction="column"*/}
                    {/*                justifyContent="space-between"*/}
                    {/*                alignItems="center"*/}
                    {/*                spacing={2}*/}
                    {/*            >*/}
                    {/*                <PublishIcon sx={myStyles.cardIconDashboard}/>*/}
                    {/*                <Typography variant={"h2"}>{t('sideMenu.Importation')}</Typography>*/}
                    {/*            </Stack>*/}
                    {/*        </CardActionArea>*/}
                    {/*    </Card>*/}
                    {/*</Grid>*/}

                </Grid>
            </Container>
        </>
    );
}

export default Dashboard;
