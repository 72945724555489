import React, {useState} from 'react';
import {useQuery} from "react-query";
import apiClient from "../../../services/apiClient";
import {useTranslation} from "react-i18next";
import {Backdrop, CircularProgress, Container} from "@mui/material";
import {BaseGrid, NotificationAlert, Title} from "../../index";
import {format} from "date-fns";
import {frCA} from "date-fns/locale";

function Logs() {
	const {t} = useTranslation();
	const [errors, setErrors] = useState({});
	const [logs, setLogs] = useState([]);

	const { isLoading, refetch: fetchAllLogs } = useQuery(
		['fetchAllLogs'],
		async () => {
			return await apiClient.fetchAllLogs();
		},
		{
			enabled: true,
			onSuccess: ({data}) => {
				setLogs(data)
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	const gridColumns = [
		{
			name: "action",
			label: t('log.Action'),
		},
		{
			name: "createdAt",
			label: t('log.Date'),
			options: {
				customBodyRender: (value) => (
					<>
						{format(new Date(value), 'PPPp', { locale: frCA } )}
					</>
				)
			}
		},
		{
			name: "user",
			label: t('log.User'),
		},
	];

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoading}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
			<NotificationAlert openNotification={errors?.msg} type='error' message={t('log.ErrorFetchingData')}/>
			<Container maxWidth="xl" sx={{pt:5, pb:5}}>
				<Title handleAdd={""} pageTitle={'log.LogsTitle'} iconTooltip={""} noAdd={true}/>
				<BaseGrid gridData={logs} gridColumn={gridColumns} />
			</Container>
		</>
	);
}

export default Logs;
