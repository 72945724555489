import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, CircularProgress, Container, Typography } from '@mui/material'
import { NotificationAlert } from '../../index'
import { useQuery } from 'react-query'
import apiClient from '../../../services/apiClient'
import Swal from 'sweetalert2'
import theme from '../../../theme'
import ContractStep from './ContractStep'
import OwnerDetails from './OwnerDetails'
import TenantsDetails from './TenantsDetails'
import ReviewInfos from './ReviewInfos'

function GestionLocataires() {
	const {t} = useTranslation();
	const [owner, setOwner] = useState({});
	const [errors, setErrors] = useState({});
	const [hasNotAgreeError, setHasNotAgree] = useState(false);
	let contract = {}

	const [steps, setSteps] = useState([
		{ key: 'firstStep', label: 'Trouver contrat', isDone: true, component: ContractStep },
		{ key: 'secondStep', label: 'Propriétaire', isDone: false, component: OwnerDetails },
		{ key: 'thirdStep', label: 'Locataires', isDone: false, component: TenantsDetails },
		{ key: 'finalStep', label: 'Finalisation', isDone: false, component: ReviewInfos },
	]);
	
	const [activeStep, setActiveStep] = useState(steps[0]);

	const handleNext = () => {
		const index = steps.findIndex(x => x.key === activeStep.key);
		if (index < steps.length - 1) {
			setSteps(prevStep => prevStep.map((x, idx) => {
				if (idx === index) x.isDone = true;
				return x;
			}));
			setActiveStep(steps[index + 1]);
		} else {
			alert('You have completed all steps.');
		}
	};

	const handleBack = () => {
		const index = steps.findIndex(x => x.key === activeStep.key);
		if (index > 0) {
			setSteps(prevStep => prevStep.map((x, idx) => {
				if (idx === index - 1) x.isDone = false;
				return x;
			}));
			setActiveStep(steps[index - 1]);
		}
	};
	

	const { isLoading: isLoadingOwner, refetch: fetchOwner } = useQuery(
		['fetchOwner'],
		async ({ queryKey }) => {
			return await apiClient.fetchOwner(contract);
		},
		{
			enabled: false,
			onSuccess: ({data}) => {
				if ((data === null || Object.keys(data).length === 0))
					setErrors({msg: true})
				else {
					Swal.fire({
						icon: 'warning',
						title: t('gestionLocataires.Confidentialite'),
						text: t('gestionLocataires.ConfidentialiteText'),
						showCancelButton: true,
						confirmButtonColor: theme.palette.primary.dark,
						confirmButtonText: t('app.Continue'),
						cancelButtonText: t('app.Cancel'),
					}).then((result) => {
						if (result.isConfirmed) {
							setOwner(data)
							setErrors({})
							setHasNotAgree(false)
							handleNext()
						} else {
							setHasNotAgree(true)
						}}
					)
				}
			},
			onError: (error) => {
				setErrors({msg: true})
			}
		}
	);

	const handleOnSubmit = (values) => {
		contract = values;
		fetchOwner();
	};

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isLoadingOwner}
			>
				<CircularProgress color="secondary" size={40}/>
			</Backdrop>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('client.ErrorFetchingData')}/>
			<Container maxWidth="xl" sx={{ pt: 5, pb: 5 }}>
				<Typography variant="h2" sx={{ fontWeight: 'bold', mb: 3 }}>{t("gestionLocataires.Title")}</Typography>
				
				<div className="box">
					<div className="steps">
						<ul className="nav">
							{steps.map((step, i) => {
								return <li key={i}
								           className={`${activeStep.key === step.key ? 'active' : ''} ${step.isDone ? 'done' : ''}`}>
									<div>Étape {i + 1}<br/><span>{step.label}</span></div>
								</li>
							})}
						</ul>
					</div>
					<div className="step-component">
						{React.createElement(activeStep.component, {
							handleOnSubmit,
							handleNext,
							handleBack,
							steps,
							activeStep,
							setOwner,
							owner,
							errors,
							hasNotAgreeError,
						})}
					</div>
				</div>
			</Container>
		</>
	)
}

export default GestionLocataires;
