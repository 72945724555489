import React, {useState} from 'react';
import styled from "@emotion/styled";
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {SideMenu} from "components";
import theme from "../../theme";

const drawerWidth = 240;

const SiteAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Header(props) {
    const [ open, setOpen ] = useState(false);
    const handleDrawer = () => {
        setOpen(!open);
    }

    return (
        <>
            <SiteAppBar position="fixed" open={open} sx={{background: theme.palette.secondary.dark}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawer}
                        edge="start">
                        <MenuIcon />
                    </IconButton>
                    <Box>
                        <Typography variant="h4" sx={{ display: { xs: 'none', sm: 'block' } }}>Profil jardins</Typography>
                    </Box>
                </Toolbar>
            </SiteAppBar>
            <SideMenu open={open} handleDrawer={handleDrawer}/>
        </>
    );
}

export default Header;