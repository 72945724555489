import React, {useState} from 'react';
import {Backdrop, CircularProgress, Container, Tooltip} from "@mui/material";
import apiClient from "../../../services/apiClient";
import {BaseGrid, myStyles, ModalCrudNotification, NotificationAlert, Title} from "components";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import {useProtectedContext} from "../../../contexts/protectedContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {getSelectedData} from "../../../utils/utils";
import Swal from 'sweetalert2';
import theme from "../../../theme";
import {useLogContext} from "../../../contexts/logContext";

function Messages() {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const { type } = useProtectedContext();
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
    const [notifications, setNotifications] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [updated, setUpdated] = useState(false);
    const [updateDelete, setUpdateDelete] = useState(false);

    const { isLoading: isLoadingNotification, refetch: fetchAllNotifications } = useQuery(
        ['fetchAllNotifications'],
        async () => {
            return await apiClient.fetchAllNotifications();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setNotifications(data)
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );


    const { mutate: deleteNotification} = useMutation(
        async (notification) => {
            return await apiClient.deleteNotification(notification);
        },
        {
            onSuccess: () => {
                setUpdateDelete(true)
                handleAction(`Suppression de la notification => ${selectedNotification.title}`)
                fetchAllNotifications()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const handleAddNotification = () => {
        setIsEditMode(false)
        setOpen(true)
        setUpdated(false)
    }

    const handleEditNotification = (notification) => {
        setSelectedNotification(notification)
        setIsEditMode(true)
        setOpen(true)
        setUpdated(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdateNotifications = () => {
        setUpdated(true)
        fetchAllNotifications()
        handleClose()

    }

    const handleDeleteNotification = (notification) => {
        //Get ID from selected notification
        setSelectedNotification(notification)
        setUpdateDelete(false)
        Swal.fire({
            icon: 'warning',
            title: t('message.DeleteTitle'),
            text: t('message.DeleteWarningText'),
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.dark,
            cancelButtonText: t('app.Cancel'),
            confirmButtonText: t('app.Yes'),
        }).then((result) => {
            if(result.value) {
                deleteNotification(notification.id)
            }}
        )
    }

    const gridNotificationColumns = [
        {
            name: "title",
            label: t('message.Title'),
        },
        {
            name: "message",
            label: t('message.Message'),
        },
        {
            name: "id",
            label: " ",
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={t('app.Edit')}>
                            <EditIcon fontSize="small" onClick={() => handleEditNotification(getSelectedData(value, notifications))} sx={myStyles.gridIcons}/>
                        </Tooltip>
                        <Tooltip title={t('app.Delete')}>
                            <DeleteForeverIcon background="#ff0000" fontSize="small" onClick={() => handleDeleteNotification(getSelectedData(value, notifications))} sx={myStyles.gridDeleteIcons}/>
                        </Tooltip>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingNotification}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={updated} type='success' message={t('message.CompletedSuccess')}/>
            <NotificationAlert openNotification={updateDelete} type='success' message={t('message.DeleteCompletedSuccess')}/>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('message.ErrorFetchingData')}/>
            <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                <ModalCrudNotification open={open} handleClose={handleClose} isEditMode={isEditMode}  selectedNotification={selectedNotification} handleUpdateNotifications={handleUpdateNotifications}/>
                <Title handleAdd={handleAddNotification} pageTitle={'message.MessageTitle'} iconTooltip={'message.AddMessage'}/>
                <BaseGrid gridData={notifications} gridColumn={gridNotificationColumns} />
            </Container>
        </>
    );
}

export default Messages;
