import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAorJy3j71cP3-Y9KBtDzpYLzwGSaLSnNw",
  authDomain: "profiljadinds.firebaseapp.com",
  projectId: "profiljadinds",
  storageBucket: "profiljadinds.appspot.com",
  messagingSenderId: "350614348798",
  appId: "1:350614348798:web:8311299a65befebeba96af",
  measurementId: "G-XT0MH6E34H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }