import React, {createContext, useContext, useEffect, useState} from 'react'
import apiClient from "../services/apiClient";
import { useQuery } from "react-query";
import { auth } from '../utils/firebase'
import { signOut, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext(null)

export const AuthContextProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false)
    const [ user, setUser ] = useState({})

    const { refetch: fetchUserAuth } = useQuery(
        "fetchUserFromToken",
        async () => {
            return await apiClient.fetchUserFromToken();
        },
        {
            enabled: false,
            onSuccess: ({data}) => {
                setUser(data)
                setInitialized(true)
            }
        }
    );

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // Obtenir et configurer le jeton
                const token = await user.getIdToken(true); // forceRefresh est true
                apiClient.setToken(token);
                await fetchUserAuth();
            } else {
                setUser(null);
            }
            setInitialized(true);
        });

        return () => unsubscribe();
    }, []);


    const handleLogout = async () => {
        debugger
        await signOut(auth)
          .then(() => {
            setUser(null);
        })
          .catch((error) => {
              // Une erreur s'est produite
          });
    };

    const authValue = { user, setUser, handleLogout, initialized }
    return (
        <AuthContext.Provider value={authValue}>
            <>{children}</>
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => useContext(AuthContext)
