import React from 'react';
import {Card, CardActionArea} from "@mui/material";
import {myStyles} from "components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function CardSendNotification({id, title, handleSendNotification}) {
	const {t} = useTranslation();

	return (
		<Card onClick={() => handleSendNotification(id)} sx={myStyles.cardSelectionRequired}>
			<CardActionArea sx={myStyles.cardSelectionRequiredAction} >
				<h3>{t(title)}</h3>
			</CardActionArea>
		</Card>
	);
}

export default CardSendNotification;