import theme from "../../theme";

const myStyles = {
    secondaryButton:{
        background: theme.palette.primary.dark,
        '&:hover':{
            background:theme.palette.primary.light
        }
    },
    backToTop:{
        background: theme.palette.primary.dark,
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    
    card:{
        width: '100%',
        textAlign: 'center',
    },
    cardIconDashboard:{
        fontSize:'60px',
    },
    cardSelectionRequired:{
        width: '100%',
        backgroundColor: theme.palette.primary.dark,
        color:'white',
        textAlign: 'center',
        '& h3': {
            fontSize: '1.5rem'
        },
        fontWeight:'bold',
    },
    cardSelectionRequiredAction:{
        p:5,
        '& .MuiCardActionArea-focusHighlight': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    cardSelected:{
        background: theme.palette.primary.light,
        color:'white',
        textAlign: 'center',
    },
    cardNotSelected:{
        background: theme.palette.primary.dark,
        color:'white',
        textAlign: 'center',
    },
    gridIcons:{
        cursor:'pointer',
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
        color:theme.palette.primary.main,
        '&:hover':{
            color:theme.palette.primary.dark
        }
    },
    iconsDefault:{
        cursor:"pointer",
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
        color:theme.palette.primary.main
    },
    iconsSuccess:{
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        color:theme.palette.primary.dark
    },
    iconsError:{
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        color:'red'
    },
    columnIcons:{
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
    },
    gridDeleteIcons:{
      cursor:'pointer',
        fontSize:'2.5rem',
        marginLeft: '10px',
        marginRight: '10px',
        color:'red',
        '&:hover':{
          color:theme.palette.primary.main
        }
    },

    iconTitle:{
        fontSize:'3.5rem',
        color: theme.palette.primary.main,
        cursor:'pointer',
        "&:hover":{
            color:theme.palette.primary.dark
        }

    },
    smallIconTitle:{
        fontSize:'1.5rem',
        color: theme.palette.primary.main,
    },
    loginContainer:{
        margin:"auto",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        padding: "100px 0",
    },
    loginLeftSide:{
        background: theme.palette.secondary.dark,
        minHeight: "500px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50px",
        padding:"50px",
        [theme.breakpoints.down('sm')]: {
            minHeight: "200px",
            padding:"20px",
        },
    },
    loginRightSide:{
        padding: "70px 0px 70px 24px",
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
        },
    },
    protectedContainer:{
        background:'white', marginTop: '65px',
        [theme.breakpoints.down('xl')]: {
            paddingLeft:"100px",
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft:"10px",
        },
    },
    DeleteOutlineIcon:{
        background: "red",
        color: "red",
    },
    selected:{
        //color: theme.palette.primary.main,
        cursor:'pointer',
        fontSize:'2.5rem',
        marginLeft: '45px',
        marginRight: '10px',
        color:theme.palette.primary.dark,
        '&:hover':{
            color:theme.palette.primary.main
        }
    },
    notSelected:{
        cursor:'pointer',
        fontSize:'2.5rem',
        marginLeft: '20px',
        marginRight: '10px',
        color:theme.palette.primary.main,
        '&:hover':{
            color:theme.palette.primary.dark
        }
    },
    emailIcon:{
        display: 'flex',
        alignItems: 'center',
        '&:hover':{
            color:theme.palette.primary.dark,
            cursor:'pointer',
        }
    },

};

export default myStyles;
