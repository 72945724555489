import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    Grid,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Autocomplete
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import theme from "../../theme";
import {Controller, useForm, useFieldArray} from "react-hook-form";
import {AlertBox, FormHelper, myStyles} from "components/index";
    import {BaseTextField} from "../textfield/StyledTextField";
import apiClient from "../../services/apiClient";
import {useMutation} from "react-query";
import {useProtectedContext} from "../../contexts/protectedContext";
import {useLogContext} from "../../contexts/logContext";
import MapAutoComplete from "../textfield/MapAutoComplete";
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Swal from 'sweetalert2';
import NumberFormat from "react-number-format";
import {value} from "lodash/seq";


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="(###) ###-####" allowEmptyFormatting mask="_"
        />
    );
});

function ModalCrudImportation({open, handleClose, isEditMode, selectedClient, roads,handleUpdateClients}) {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const { clientConfigType } = useProtectedContext()
    const [errors, setErrors] = useState({});
    const [changeMade, setChangeMade] = useState(false);
    const [buildingTypeInt, setBuildingTypeInt] = useState(1);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [numberError, setNumberError] = useState(false);
    const [roadId, setRoadId] = useState("");

    const { control,reset, handleSubmit, setValue, watch  } = useForm({
        reValidateMode: "onBlur",
        defaultValues: {
            clientId: selectedClient?.clientId || "",
            clientRoadId: selectedClient?.clientRoadId || "",
            clientContract: selectedClient?.contract || "",
            clientReference: selectedClient?.reference || "",
            clientAddress: selectedClient?.address || "",
            clientTelNumber: selectedClient?.telephoneNumber || "",
            clientCellNumber: selectedClient?.cellphoneNumber || "",
            clientBuildingTypeId: selectedClient?.buildingTypeId || "",
            clientEmail: selectedClient?.email || "",
            clientName: selectedClient?.clientName|| "",
            clientCallAlert: selectedClient?.callAlert || false,
            clientSmsAlert: selectedClient?.smsAlert || false,
            clientEmailAlert: selectedClient?.emailAlert || false,
            tenants: selectedClient?.tenants || [],
            setRoadId: selectedClient?.clientRoadId || "",
        }
    });

    const fieldNames = ['clientId', 'clientRoadId', 'clientContract', 'clientReference', 'clientAddress', 'clientTelNumber', 'clientCellNumber', 'clientBuildingTypeId', 'clientName', 'clientCallAlert', 'clientSmsAlert','clientEmail'];
    const watchFields = watch(fieldNames);

    const onFieldsChange = (e) => {
        setChangeMade(true);
    };

    const { fields: tenants, append: appendTenantsRow, remove: removeTenantsRow } = useFieldArray({
        control,
        name: "tenants"
    });

    const { isLoading: isLoadingCrud, mutate: crudClients } = useMutation(
        async (selectedClient) => {
            return await apiClient.crudImportation(selectedClient);
        },
        {
            onSuccess: ({data}) => {
                handleAction(`Importation manuelle du client => ${data.clientName}`)
                handleUpdateClients()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const {mutate: deleteTenant} = useMutation(
        async (index) => {
            const updatedItems = [...tenants];
            updatedItems.splice(index, 1);
            return await apiClient.deleteErrorTenant(tenants[index].tenantId);
        },
        {
            onSuccess: ({data}) => {
                handleAction(`Suppression du locataire => ${data.phoneNumber}`)
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const onRoadChange = (e,value) => {
        setRoadId(value.id)
        setValue('clientRoadId', value.name)
    }




    useEffect(() => {
        roads.map((road) => {
            road.label = road.name
        })

        setNumberError(false)
        setChangeMade(false);
        if (isEditMode) {
            setBuildingTypeInt(selectedClient?.buildingTypeId)
            setRoadId(selectedClient?.clientRoadId)
            const roadId_ = roads.find(road => road.id === selectedClient?.clientRoadId)?.name
            setValue('clientContract', selectedClient?.contract)
            setValue('clientReference', selectedClient?.reference)
            setValue('clientRoadId', selectedClient?.clientRoadId)
            setValue('clientAddress', selectedClient?.address)
            setValue('clientTelNumber', selectedClient?.telephoneNumber)
            setValue('clientCellNumber', selectedClient?.cellphoneNumber)
            setValue('clientBuildingTypeId', buildingTypeInt)
            setValue('clientName', selectedClient?.clientName)
            setValue('clientCallAlert', selectedClient?.callAlert)
            setValue('clientSmsAlert', selectedClient?.smsAlert)
            setValue('clientEmailAlert', selectedClient?.emailAlert)
            setValue('tenants',selectedClient?.tenants)
            setValue('clientEmail', selectedClient?.email)

            if(selectedClient?.telephoneNumber === ""){
                setNumberError(true)
            }
            else if(selectedClient?.telephoneNumber.length < 10 && selectedClient?.cellphoneNumber === ""){
                setNumberError(true)
            }
        }
        else{
            setRoadId(null)
            setBuildingTypeInt(0)
            setValue('tenants', [])
            setValue('clientContract', "")
            setValue('clientReference', "")
            setValue('clientRoadId', "")
            setValue('clientAddress', "")
            setValue('clientTelNumber', "")
            setValue('clientCellNumber', "")
            setValue('clientBuildingTypeId', "")
            setValue('clientBuildingTypeName', "")
            setValue('clientName', "")
            setValue('clientCallAlert', false)
            setValue('clientSmsAlert', false)
            setValue('clientEmailAlert', false)
            setValue('clientEmail', "")
        }
    }, [selectedClient, open])

    const addNewTenants = () => {
        appendTenantsRow({ phoneNumber: "", smsAlert: false, callAlert: false })
        onFieldsChange()
    };

    const handleCloseDialog = () => {
        changeMade ? (
            Swal.fire({
                icon: 'warning',
                title: t('client.Exit'),
                text: t('client.ExitConfirm'),
                showCancelButton: true,
                confirmButtonColor: theme.palette.primary.dark,
                cancelButtonText: t('app.Cancel'),
                confirmButtonText: t('app.Yes'),
            }).then((result) => {
                if(result.value) {
                    resetFormAndWindow();
                }}
            )
        ) : (
            resetFormAndWindow()
        )
    }

    const resetFormAndWindow = () => {
        reset();
        setChangeMade(false);
        setErrors({});
        handleClose();
    }

    const handleOnSubmit = (values) => {
        const roadId_ = roads.find(road => road.name === values.clientRoadId)?.id

        checkPhoneNumber(values)

        if(numberError===false) {
            crudClients({
                clientId: isEditMode ? selectedClient?.id : 0,
                clientName: values.clientName,
                clientEmail: values.clientEmail,
                clientContract: values.clientContract,
                clientReference: values.clientReference,
                clientAddress: values.clientAddress,
                clientCellNumber: values.clientCellNumber,
                clientTelNumber: values.clientTelNumber,
                clientBuildingTypeId: buildingTypeInt,
                clientCallAlert: values.clientCallAlert,
                clientSmsAlert: values.clientSmsAlert,
                clientEmailAlert: values.clientEmailAlert,
                clientRoadId: roadId_ || selectedClient?.clientRoadId,
                tenants: values.tenants,
            });
        }
    }

    const checkPhoneNumber = (values) => {
        const telNumber = values.clientTelNumber
        const cellNumber = values.clientCellNumber
        if(telNumber === "" || telNumber.length < 10){
            setNumberError(true)
        } else setNumberError(false)
        if(cellNumber === "" && values?.clientSmsAlert === true){
            if(cellNumber === "" || cellNumber < 10) {
                document.getElementById('number-error').innerHTML
                    = t('client.SMSError');
                setNumberError(true)
            }
        }
    }

    const changedBuildingType = (id) => {
        onFieldsChange()
        switch (id) {
            case 1:
                setBuildingTypeInt(1);
                break;
            case 2:
                setBuildingTypeInt(2);
                break;
            case 3:
                setBuildingTypeInt(3);
                break;
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose= {handleCloseDialog}
            scroll= {"paper"}
            ariel-labelledby="scroll-dialog-crud"
            aria-describedby="scroll-dialog-description-crud"
            maxWidth="md"
        >
            <DialogTitle id="responsive-dialog-crud">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h3" sx={{fontWeight:'bold'}}>{ isEditMode ? t('client.EditClient') : t('client.AddClient')}</Typography>
                    <HighlightOffIcon sx={myStyles.iconTitle} onClick={() => {
                        handleCloseDialog();
                    }}/>
                </Stack>
            </DialogTitle>
            <DialogContent dividers={true}>
                {
                    errors?.hasError && <AlertBox type={"error"} message={t('client.ErrorAddClients')}/>
                }
                <Box component = "form" onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container spacing={2} >
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="clientContract"
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        label={t('client.Contract')}
                                        error={selectedClient.contract === 0 ? true : false}
                                        sx={{mb:0}}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="clientReference"
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label={t('client.Reference')}
                                        sx={{mb:0}}
                                        error={selectedClient.reference === 0 ? true : false || selectedClient.reference === "" ? true : false || error !== undefined}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}
                                        color={selectedClient.clientReference === 0 ? "error" : "primary"}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="clientRoadId"
                                variant="outlined"
                                rules={{
                                    required: false
                                }}
                                render={({field, fieldState: { error } }) => (
                                    <Autocomplete
                                        value={roads.find(road => road.id === selectedClient?.clientRoadId)?.name}
                                        options={roads}
                                        fullWidth
                                        onChange={(e,value) => {
                                            onFieldsChange();
                                            onRoadChange(e,value);
                                        }}
                                        renderInput={(params) => <BaseTextField {...params} label={t('client.Road')} />}
                                    />

                                )
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="clientEmail"
                                rules={{
                                    required: true,
                                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label={t('client.Email')}
                                        sx={{mb:0}}
                                        error={error !== undefined || selectedClient.email === "" ? true : false}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="clientName"
                                rules={{
                                    required: true
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        fullWidth
                                        variant="outlined"
                                        label={t('client.Name')}
                                        sx={{mb:0}}
                                        error={error !== undefined || selectedClient.clientName === "" ? true : false}
                                        helperText={error ? t(FormHelper.required[error.type]) : ""}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="errorLabel"
                                render={({ field, fieldState: { error } }) => (
                                    <label id="number-error" className="rsvp required-fields"  style={{display:numberError ? "block" : "none", color:"red"}}>{t('client.InvalidNumber')}</label>

                                )}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="clientTelNumber"
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}
                                        sx={{mb:0}}
                                        error={error !== undefined}
                                        label={t('client.Phone')}
                                        fullWidth
                                        id="formatted-mainPhone-input"
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="clientCellNumber"
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            onFieldsChange();
                                        }}
                                        sx={{mb:0}}
                                        error={error !== undefined}
                                        label={t('client.Cellphone')}
                                        fullWidth
                                        id="formatted-cellPhone-input"
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Controller
                                control={control}
                                name="clientCallAlert"
                                defaultValue={false}
                                render={({ field }) => (
                                    <>
                                        <FormControlLabel
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onFieldsChange();
                                            }}
                                            control={
                                                <Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field} />
                                            }
                                            label={t('client.PhoneAlert')}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <Controller
                                control={control}
                                name="clientSmsAlert"
                                defaultValue={false}
                                render={({ field }) => (
                                    <>
                                        <FormControlLabel
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onFieldsChange();
                                            }}
                                            control={
                                                <Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field}
                                                />}
                                            label={t('client.SMSAlert')}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <Controller
                                control={control}
                                name="clientEmailAlert"
                                defaultValue={false}
                                render={({ field }) => (
                                    <>
                                        <FormControlLabel
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                onFieldsChange();
                                            }}
                                            control={
                                                <Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field}
                                                />}
                                            label={t('client.EmailAlert')}
                                        />
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="clientAddress"
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                                render={({ field: { ref, onChange, ...field}, fieldState  }) => (

                                    <MapAutoComplete
                                        field={field}
                                        fullWidth
                                        onChange={(e) => {
                                            onChange(e);
                                            onFieldsChange();
                                        }}
                                        errorField={!!fieldState.error}
                                        helperText={fieldState.error ? t(FormHelper.required[fieldState.error.type]) : ""}

                                    />

                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} justifyContent="center">
                            <Controller
                                control={control}
                                name="clientResidentialBuilding"
                                render={({ field: { value, onChange, ...field } }) => (
                                    <FormGroup>
                                        <HomeIcon id="homeIcon" fontSize="large" onClick={ () => changedBuildingType(1) } sx={buildingTypeInt === 1 ? myStyles.selected : myStyles.notSelected}/>
                                    </FormGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Controller
                                control={control}
                                name="clientCommercialBuilding"
                                render={({ field: { value, onChange, ...field } }) => (
                                    <FormGroup>
                                        <BusinessIcon id="businessIcon" fontSize="large" onClick={ () => changedBuildingType(2) }  sx={buildingTypeInt === 2 ? myStyles.selected : myStyles.notSelected}/>
                                    </FormGroup>
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Controller
                                control={control}
                                name="clientApartmentBuilding"
                                render={({ field: { value, onChange, ...field } }) => (

                                    <FormGroup>
                                        <ApartmentIcon id="apartmentIcon" fontSize="large" onClick={ () => changedBuildingType(3) } sx={buildingTypeInt === 3 ? myStyles.selected : myStyles.notSelected}/>
                                    </FormGroup>

                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained"  onClick={addNewTenants}>
                                {t('client.AddPhoneNumber')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            { tenants.map( (field, index) => (
                                <Grid container key={field.id} spacing={1} alignItems="center">
                                    <Grid item xs={5}>
                                        <Controller
                                            control={control}
                                            name={`tenants.${index}.phoneNumber`}
                                            render={({ field, fieldState: { error } }) => (
                                                <BaseTextField
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        onFieldsChange();
                                                    }}
                                                    sx={{mb:0}}
                                                    label={t('client.Phone')}
                                                    fullWidth
                                                    id={`tenants.${index}.phoneNumber`}
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                    }}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}  style={{ display: 'block' }}>
                                        <Controller
                                            control={control}
                                            name={`tenants.${index}.smsAlert`}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field} />}
                                                        label="SMS (Texto)"
                                                    />
                                                </>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`tenants.${index}.callAlert`}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={field.value} sx={{ pt:0, pb:0 }} {...field} />}
                                                        label="Téléphone"
                                                    />
                                                </>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            fullWidth
                                            color="error"
                                            variant="text"
                                            onClick={() => {
                                                deleteTenant(index)
                                                removeTenantsRow(index)
                                                onFieldsChange()
                                            }}
                                        >
                                            {t('app.Delete')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'left', justifyContent: 'left-end'}}>
                            <Button fullWidth variant="contained" onClick={ () =>  handleCloseDialog() } >
                                {t('app.Cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'right', justifyContent: 'right-end'}}>
                            <Button fullWidth variant="contained" type="submit">
                                {t('app.Save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default ModalCrudImportation;