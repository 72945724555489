import React, {useState} from 'react';
import {Backdrop, CircularProgress, Container, Tooltip} from "@mui/material";
import apiClient from "../../../services/apiClient";
import {BaseGrid, myStyles, ModalCrudClient, NotificationAlert, Title} from "components";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "react-query";
import {useProtectedContext} from "../../../contexts/protectedContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {getSelectedData} from "../../../utils/utils";
import Swal from 'sweetalert2';
import theme from "../../../theme";
import {useLogContext} from "../../../contexts/logContext";
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';

function Clients() {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const { type } = useProtectedContext();
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
    const [clients, setClient] = useState([]);
    const [roads, setRoads] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [updated, setUpdated] = useState(false);
    const [updateDelete, setUpdateDelete] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const { isLoading: isLoadingClient, refetch: fetchAllClients } = useQuery(
        ['fetchAllClients'],
        async () => {
            setLoading(true)
            return await apiClient.fetchAllClients();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setLoading(false)
                setClient(data)
            },
            onError: (error) => {
                setLoading(false)
                setErrors({msg: true})
            }
        }
    );

    const {mutate : fetchAllRoads} = useQuery(
        ['fetchAllRoads'],
        async () => {
            setLoading(true)
            return await apiClient.fetchAllRoads();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setLoading(false)
                setRoads(data)
            },
            onError: (error) => {
                setLoading(false)
                setErrors({msg: true})
            }
        }
    );


    const { mutate: deleteClient} = useMutation(
        async (clientId) => {
            setLoading(true)
            return await apiClient.deleteClient(clientId);
        },
        {
            onSuccess: () => {
                setLoading(false)
                setUpdateDelete(true)
                handleAction(`Suppression du client => ${selectedClient.clientName}`)
                fetchAllClients()
            },
            onError: (error) => {
                setLoading(false)
                setErrors({msg: true})
            }
        }
    );

    const handleAddClient = () => {
        setIsEditMode(false)
        setOpen(true)
        setUpdated(false)
    }

    const handleEditClient = (client) => {
        setSelectedClient(client)
        setIsEditMode(true)
        setOpen(true)
        setUpdated(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdateClients = () => {
        setUpdated(true)
        fetchAllClients()
        handleClose()
    }

    const handleDeleteClient = (client) => {
        setSelectedClient(client)
        setUpdateDelete(false)
        Swal.fire({
            icon: 'warning',
            title: t('client.DeleteTitle'),
            text: t('client.DeleteWarningText'),
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.dark,
            cancelButtonText: t('app.Cancel'),
            confirmButtonText: t('app.Yes'),
        }).then((result) => {
            if(result.value) {
                deleteClient(client.id)
            }}
        )
    }

    const buildingTypeIcon = (buildingTypeId) => {
        let icon_;
        switch (buildingTypeId) {
            case 1:
                 return  <HomeIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
            case 2:
                return <BusinessIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
            case 3:
                return <ApartmentIcon fontSize="small" sx={myStyles.columnIcons}/>
                break;
        }
    }

    const gridClientColumns = [
        {
            name: "clientFullName",
            label: t('client.Name'),
        },
        {
            name: "clientTel",
            label: t('client.Phone'),

        },
        {
            name: "clientMobile",
            label: t('client.Cellphone'),
        },
        {
            name: "clientAddresse",
            label: t('client.Address'),
        },
        {
            name: "clientContractNumber",
            label: t('client.Contract'),
        },
        {
            name: "clientReference",
            label: t('client.Reference'),
        },
        {
            name: "buildingTypeFK",
            label: t('client.BuildingType'),
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={value === 1 ?('Résidentiel') : value === 2 ? t('Commercial') : t('Multi-Logement')}>
                        <div>
                        {buildingTypeIcon(value)}
                        </div>
                        </Tooltip>
                        </>
                )
            }
        },
        {
            name: "id",
            label: " ",
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={t('app.Edit')}>
                            <EditIcon fontSize="small" onClick={() => handleEditClient(getSelectedData(value, clients))} sx={myStyles.gridIcons}/>
                        </Tooltip>
                        <Tooltip title={t('app.Delete')}>
                            <DeleteForeverIcon background="#ff0000" fontSize="small" onClick={() => handleDeleteClient(getSelectedData(value, clients))} sx={myStyles.gridDeleteIcons}/>
                        </Tooltip>
                    </>
                )
            }
        },
    ];

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={updated} type='success' message={t('client.CompletedSuccess')}/>
            <NotificationAlert openNotification={updateDelete} type='success' message={t('client.DeleteCompletedSuccess')}/>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('client.ErrorFetchingData')}/>
            <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                <ModalCrudClient open={open} handleClose={handleClose} isEditMode={isEditMode}  selectedClient={selectedClient} roads={roads} handleUpdateClients={handleUpdateClients}/>
                <Title handleAdd={handleAddClient} pageTitle={'client.ClientTitle'} iconTooltip={'client.AddClient'} roads={roads}/>
                <BaseGrid gridData={clients} gridColumn={gridClientColumns} />
            </Container>
        </>
    );
}

export default Clients;
