import React, { useState } from 'react'
import { Backdrop, CircularProgress, Container, Tooltip } from '@mui/material'
import apiClient from '../../../services/apiClient'
import { BaseGrid, ModalCrudRoad, myStyles, NotificationAlert, Title } from 'components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useProtectedContext } from '../../../contexts/protectedContext'
import EditRoadIcon from '@mui/icons-material/EditRoad'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { getSelectedData } from '../../../utils/utils'
import Swal from 'sweetalert2'
import theme from '../../../theme'
import { useLogContext } from '../../../contexts/logContext'

function Roads() {
    const {t} = useTranslation();
    const { handleAction } = useLogContext()
    const { enterprise } = useProtectedContext();
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // true = edit, false = add
    const [roads, setRoads] = useState([]);
    const [selectedRoad, setSelectedRoad] = useState({});
    const [updated, setUpdated] = useState(false);
    const [updateDelete, setUpdateDelete] = useState(false);


    const { isLoading: isLoadingRoads, refetch: fetchAllRoads } = useQuery(
        ['fetchAllRoads'],
        async () => {
            return await apiClient.fetchAllRoads();
        },
        {
            enabled: true,
            onSuccess: ({data}) => {
                setRoads(data)
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const { mutate: deleteRoad } = useMutation(
        async (road) => {
            return await apiClient.deleteRoad(road.id);
        },
        {
            onSuccess: () => {
                setUpdateDelete(true)
                handleAction(`Suppression de la route => ${selectedRoad.roadName}`)
                fetchAllRoads()
            },
            onError: (error) => {
                setErrors({msg: true})
            }
        }
    );

    const handleAddRoad = () => {
        setIsEditMode(false)
        setOpen(true)
        setUpdated(false)
    }

    const handleEditRoad = (road) => {
        setSelectedRoad(road)
        setIsEditMode(true)
        setOpen(true)
        setUpdated(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleUpdateRoads = () => {
        setUpdated(true)
        fetchAllRoads()
        handleClose()
    }

    const handleDeleteRoad = (road) => {
        setSelectedRoad(road)
        setUpdateDelete(false)
        Swal.fire({
            icon: 'warning',
            title: t('roads.DeleteTitle'),
            text: t('roads.DeleteWarningText'),
            showCancelButton: true,
            confirmButtonColor: theme.palette.primary.dark,
            confirmButtonText: t('app.Yes'),
            cancelButtonText: t('app.Cancel'),
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRoad(road)
            }}
        )
    }

    const gridRoadColumns = [
        {
            name: "roadName",
            label: t('roads.Name'),
        },
        {
            name: "roadDescription",
            label: t('roads.Description'),
        },
        {
            name: "enterpriseFK",
            label: t('roads.Enterprise'),
            options: {
                customBodyRender: (value) => {
                    return enterprise.filter((ent) => ent.id === value)[0]?.name || 'Entreprise non trouvée'
                },
            },
        },
        {
            name: "id",
            label: " ",
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={t('app.Edit')}>
                            <EditRoadIcon onClick={() => handleEditRoad(getSelectedData(value, roads))} sx={myStyles.gridIcons}/>
                        </Tooltip>

                    </>
                )
            }
        },
        {
            name: "id",
            label: " ",
            options: {
                customBodyRender: (value) => (
                    <>
                        <Tooltip title={t('app.Delete')}>
                            <DeleteForeverIcon onClick={() => handleDeleteRoad(getSelectedData(value, roads))} sx={myStyles.gridDeleteIcons}/>
                        </Tooltip>

                    </>
                )
            }
        },
    ];

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingRoads}
            >
                <CircularProgress color="secondary" size={40}/>
            </Backdrop>
            <NotificationAlert openNotification={updated} type='success' message={t('roads.CompletedSuccess')}/>
            <NotificationAlert openNotification={updateDelete} type='success' message={t('roads.DeleteCompletedSuccess')}/>
            <NotificationAlert openNotification={errors?.msg} type='error' message={t('roads.ErrorFetchingData')}/>
            <Container maxWidth="xl" sx={{pt:5, pb:5}}>
                <ModalCrudRoad open={open} handleClose={handleClose} isEditMode={isEditMode} enterprise={enterprise} selectedRoad={selectedRoad} handleUpdateRoads={handleUpdateRoads}/>
                <Title handleAdd={handleAddRoad} pageTitle={'roads.RoadsTitle'} iconTooltip={'roads.AddRoad'}/>
                <BaseGrid gridData={roads} gridColumn={gridRoadColumns} />
            </Container>
        </>
    );
}

export default Roads;
