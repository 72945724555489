import './asset/css/App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthContextProvider} from "./contexts/authContext";
import {
	Dashboard,
	Login,
	Logs,
	PageDoesNotExist,
	ProtectedRoute,
	PublicRoute,
	SelectNotificationPage,
	SelectRoadPage,
	SendNotifications,
	Messages,
	Roads,
	Importation,
	Clients,
	Tutorials,
	SelectTutorialsNotificationPage,
	SelectTutorialsRoadPage,
	TutorialsMessages,
	Avis,
	Unsubscribe,
	GestionLocataires,
	SelectAvisNotificationPage, GestionAvis
} from './components'
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import SelectAvisRoadPage from './components/pages/selectAvisRoadPage/SelectAvisRoadPage'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnmount: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
});

export default function AppContainer() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<App/>
				<ReactQueryDevtools initialIsOpen={false} />
			</AuthContextProvider>
		</QueryClientProvider>
	)
};

function App() {
	return (
		<div className="App" id="top-anchor">
			<BrowserRouter>
				<Routes>
					<Route exact path='/' element={<PublicRoute element={<GestionLocataires/>} />} />
					<Route exact path='/login' element={<PublicRoute element={<Login/>} />} />
					<Route exact path='/gestionlocataires' element={<PublicRoute element={<GestionLocataires/>} />} />
					<Route exact path='/unsubscribe/:id' element={<PublicRoute element={<Unsubscribe/>} />} />
					<Route exact path='/unauthorized' element={<PublicRoute element={<Login message="app.MustBeAuthenticated"/>} />} />
					<Route exact path='/dashboard' element={<ProtectedRoute element={<Dashboard/>} />} />
					<Route exact path='/avis' element={<ProtectedRoute element={<Avis/>} />} />
					<Route exact path='/avis/:enterprise' element={<ProtectedRoute element={<SelectAvisNotificationPage/>} />} />
					<Route exact path='/avis/:enterprise/:idEmailMessage' element={<ProtectedRoute element={<SelectAvisRoadPage/>} />} />
					<Route exact path='/gestionavis' element={<ProtectedRoute element={<GestionAvis/>} />} />
					<Route exact path='/messages' element={<ProtectedRoute element={<Messages/>} />} />
					<Route exact path='/generalmessages' element={<ProtectedRoute element={<TutorialsMessages/>} />} />
					<Route exact path='/roads' element={<ProtectedRoute element={<Roads/>} />} />
					<Route exact path='/clients' element={<ProtectedRoute element={<Clients/>} />} />
					<Route exact path='/importation' element={<ProtectedRoute element={<Importation/>} />} />
					<Route exact path='/logs' element={<ProtectedRoute element={<Logs/>} />} />
					<Route exact path='/notifications' element={<ProtectedRoute element={<SendNotifications/>} />} />
					<Route exact path='/notifications/:enterprise' element={<ProtectedRoute element={<SelectNotificationPage/>} />} />
					<Route exact path='/notifications/:enterprise/:notification/' element={<ProtectedRoute element={<SelectRoadPage/>} />} />
					<Route exact path='/general' element={<ProtectedRoute element={<Tutorials/>} />} />
					<Route exact path='/general/:enterprise' element={<ProtectedRoute element={<SelectTutorialsNotificationPage/>} />} />
					<Route exact path='/general/:enterprise/:notification/' element={<ProtectedRoute element={<SelectTutorialsRoadPage/>} />} />
					<Route path='*' element={<ProtectedRoute element={<PageDoesNotExist/>} />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}
