import React from 'react';
import {EnterpriseSelectionCard} from "components";


function SendNotifications() {
	return (
		<EnterpriseSelectionCard url="notifications" />
	);
}

export default SendNotifications;
